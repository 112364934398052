import Image from 'next/image';
import styled from 'styled-components';

import Button from '@polyai/ui/components/atoms/Button';
import Clickable from '@polyai/ui/components/atoms/Clickable';
import { Title as BaseTitle } from '@polyai/ui/components/atoms/LegacyText';
import breakpoints, { under } from '@polyai/ui/styles/config/breakpoints';
import themeColors from '@polyai/ui/styles/config/colors';
import themeSpacing from '@polyai/ui/styles/config/spacing';
import text from '@polyai/ui/styles/config/text';

export const DotsContainer = styled.div`
  position: fixed;
  z-index: -1;
  inset: 0;

  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${themeColors.backgroundPage};
`;

export const BackgroundImage = styled(Image)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const UnauthorisedContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeSpacing.lg1};
  justify-content: center;

  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  padding: ${themeSpacing.lg1} ${themeSpacing.lg3};

  @media (${under(breakpoints.sm)}) {
    gap: ${themeSpacing.md2};
    max-width: 700px;
    padding: ${themeSpacing.md2};
  }

  @media (${under(breakpoints.xs)}) {
    padding: ${themeSpacing.md2} ${themeSpacing.md1};
  }
`;

export const DemoLink = styled(Clickable)`
  display: inline-flex;
  gap: ${themeSpacing.xxs2};
  align-items: center;

  padding: 0 ${themeSpacing.xs1};

  font-weight: ${text.weight.semibold};
  color: ${themeColors.textPrimary};
`;

export const UnauthorisedContent = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 450px;
`;

export const Title = styled(BaseTitle)`
  margin-bottom: ${themeSpacing.md3};
  font-size: ${text.size.heading.h1};
`;

export const BulletPoints = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeSpacing.sm3};
  margin-bottom: ${themeSpacing.md2};
`;

export const BulletPoint = styled.div`
  display: flex;
  gap: ${themeSpacing.xs3};
`;

export const BulletDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeSpacing.xxs2};
`;

export const SignInButton = styled(Button)`
  margin-bottom: ${themeSpacing.sm3};
`;
