/* eslint-disable max-len */
import { FC } from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const ExportIcon: FC<IconProps> = (props) => {
  return (
    <BaseIcon {...props}>
      <path d="M11.453 10.197a1 1 0 0 0 1.41-.094L15 7.661v12.895a1 1 0 1 0 2 0V7.66l2.136 2.442a1 1 0 0 0 1.505-1.317l-3.878-4.432A.995.995 0 0 0 16 4a.998.998 0 0 0-.764.354l-3.878 4.432a1 1 0 0 0 .095 1.411ZM6 13v12h20V13h-1a1 1 0 1 1 0-2h2a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V12a1 1 0 0 1 1-1h2a1 1 0 1 1 0 2H6Z" />
    </BaseIcon>
  );
};
