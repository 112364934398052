/* eslint-disable max-len */
import React, { FC } from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const ResponseControlIcon: FC<IconProps> = (props) => {
  return (
    <BaseIcon {...props}>
      <path d="M8 13a1 1 0 0 1 1-1h8a1 1 0 1 1 0 2H9a1 1 0 0 1-1-1Zm0 4a1 1 0 0 1 1-1h5a1 1 0 1 1 0 2H9a1 1 0 0 1-1-1Zm16 7.5a2 2 0 1 1 0-4 2 2 0 0 1 0 4Zm0 2a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z" />
      <path d="M19 21.5h2v2h-2v-2Zm.793 3.793 1.5-1.5 1.414 1.414-1.5 1.5-1.414-1.414Zm7 1.414 1.414-1.414-1.5-1.5-1.414 1.414 1.5 1.5ZM23 27.5v-2h2v2h-2Zm6-4h-2v-2h2v2Zm-6-4v-2h2v2h-2Zm5.207.207-1.414-1.414-1.5 1.5 1.414 1.414 1.5-1.5Zm-7-1.414 1.5 1.5-1.414 1.414-1.5-1.5 1.414-1.414Z" />
      <path d="M4 8a2 2 0 0 1 2-2h20a2 2 0 0 1 2 2v8h-2V8H6v16.086l2.293-2.293A1 1 0 0 1 9 21.5h8.5v2H9.414L4 28.914V8Z" />
    </BaseIcon>
  );
};
