import { ReactNode } from 'react';

import {
  AnalyticsIcon,
  BuildIcon,
  DocumentationIcon,
  ExternalLinkIcon,
  VisibilityIcon,
} from '@polyai/ui/components/atoms/Icons';
import {
  Body,
  BodyStrong,
  Caption,
} from '@polyai/ui/components/atoms/LegacyText';
import { Logo } from '@polyai/ui/components/atoms/Logo';

import { useAppSelector } from 'hooks/reduxHooks';

import * as Styled from './Login.styled';

type BulletPoint = {
  icon: ReactNode;
  title: string;
  description: string;
};

const BULLET_POINTS: BulletPoint[] = [
  {
    icon: <AnalyticsIcon color="iconBrand" size="xl" />,
    title: 'Monitor',
    description: 'Track your voice assistant success',
  },
  {
    icon: <VisibilityIcon color="iconBrand" size="xl" />,
    title: 'Analyze',
    description: 'Gain insights into your customer conversations',
  },
  {
    icon: <DocumentationIcon color="iconBrand" size="xl" />,
    title: 'Inspect',
    description: 'Customize metrics to match your goals',
  },
  {
    icon: <BuildIcon color="iconBrand" size="xl" />,
    title: 'Configure',
    description: "Improve some of your assistant's behavior",
  },
];

const LoginScreen = () => {
  const login = useAppSelector((state) => state.auth.login);
  return (
    <>
      <Styled.DotsContainer>
        <Styled.BackgroundImage
          alt=""
          height={1235}
          src="/assets/jpg/login-background.jpg"
          width={2375}
        />
      </Styled.DotsContainer>
      <Styled.UnauthorisedContainer>
        <Logo
          dotsColor="iconPrimary"
          height={52}
          textColor="iconPrimary"
          width={122}
        />
        <Styled.UnauthorisedContent>
          <Styled.Title>Sign in to PolyAI</Styled.Title>
          <Styled.BulletPoints>
            {BULLET_POINTS.map((bullet) => (
              <Styled.BulletPoint key={bullet.title}>
                {bullet.icon}
                <Styled.BulletDetails>
                  <BodyStrong>{bullet.title}</BodyStrong>
                  <Caption>{bullet.description}</Caption>
                </Styled.BulletDetails>
              </Styled.BulletPoint>
            ))}
          </Styled.BulletPoints>
          <div>
            <Styled.SignInButton
              label="Sign in"
              onClick={() => login(window.location.pathname)}
            />
            <Body>
              Don’t have an account?{' '}
              <Styled.DemoLink
                hoverSize="lg"
                hoverTargetColor="iconPrimaryHover"
                to="https://poly.ai/request-a-demo/"
                hoverTarget
              >
                Get in touch <ExternalLinkIcon color="textPrimary" size="sm" />
              </Styled.DemoLink>
            </Body>
          </div>
        </Styled.UnauthorisedContent>
      </Styled.UnauthorisedContainer>
    </>
  );
};

export default LoginScreen;
