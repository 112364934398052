/* eslint-disable max-len */
import { FC } from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const MetricIcon: FC<IconProps> = (props) => {
  return (
    <BaseIcon {...props}>
      <path d="M11.874 27A4.002 4.002 0 0 1 4 26a4 4 0 0 1 7.874-1H27a1 1 0 1 1 0 2H11.874ZM10 26a2 2 0 1 0-4 0 2 2 0 0 0 4 0Zm1.874-19A4.002 4.002 0 0 1 4 6a4 4 0 0 1 7.874-1H27a1 1 0 1 1 0 2H11.874ZM10 6a2 2 0 1 0-4 0 2 2 0 0 0 4 0Zm1.874 11A4.002 4.002 0 0 1 4 16a4 4 0 0 1 7.874-1H27a1 1 0 1 1 0 2H11.874ZM10 16a2 2 0 1 0-4 0 2 2 0 0 0 4 0Z" />
    </BaseIcon>
  );
};
