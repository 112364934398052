import React from "react";

import styled, { css } from "styled-components";

const HeadingBaseStyles = css`
  font-family: ${({ theme }) => theme.text.font.heading};
  line-height: ${({ theme }) => theme.text.lineHeight.tight};
  letter-spacing: ${({ theme }) => theme.text.letterSpacing.tight};
`;

const BodyBaseStyles = css`
  font-family: ${({ theme }) => theme.text.font.body};
`;

export const Headline = styled(({ as, ...props }) =>
  React.cloneElement(as, props)
)`
  ${HeadingBaseStyles}
  font-size: ${({ theme }) => theme.text.size.heading.h1};
  font-weight: ${({ theme }) => theme.text.weight.bold};
`;

export const Subtitle = styled(({ as, ...props }) =>
  React.cloneElement(as, props)
)`
  ${HeadingBaseStyles}
  font-size: ${({ theme }) => theme.text.size.heading.h4};
  font-weight: ${({ theme }) => theme.text.weight.semibold};
`;

export const Title = styled(({ as, ...props }) =>
  React.cloneElement(as, props)
)`
  ${HeadingBaseStyles}
  font-size: ${({ theme }) => theme.text.size.heading.h4};
  font-weight: ${({ theme }) => theme.text.weight.semibold};
`;
export const Body = styled(({ as, ...props }) => React.cloneElement(as, props))`
  ${BodyBaseStyles}
  font-size: ${({ theme }) => theme.text.size.body.body3};
  font-weight: ${({ theme }) => theme.text.weight.regular};
  line-height: ${({ theme }) => theme.text.lineHeight.tight};
`;

export const BodyStrong = styled(({ as, ...props }) =>
  React.cloneElement(as, props)
)`
  ${BodyBaseStyles}
  font-size: ${({ theme }) => theme.text.size.body.body3};
  font-weight: ${({ theme }) => theme.text.weight.semibold};
  line-height: ${({ theme }) => theme.text.lineHeight.tight};
`;

export const InputText = styled(({ as, ...props }) =>
  React.cloneElement(as, props)
)`
  ${BodyBaseStyles}
  font-size: ${({ theme }) => theme.text.size.body.body4};
  font-weight: ${({ theme }) => theme.text.weight.semibold};
  line-height: ${({ theme }) => theme.text.lineHeight.tight};
  text-transform: uppercase;
  letter-spacing: ${({ theme }) => theme.text.letterSpacing.normal};
`;

export const Caption = styled(({ as, ...props }) =>
  React.cloneElement(as, props)
)`
  font-size: ${({ theme }) => theme.text.size.body.body4};
  font-weight: ${({ theme }) => theme.text.weight.regular};
  line-height: ${({ theme }) => theme.text.lineHeight.tight};
  letter-spacing: ${({ theme }) => theme.text.letterSpacing.normal};
`;

export const CaptionStrong = styled(Caption)`
  font-weight: ${({ theme }) => theme.text.weight.semibold};
`;

export const MenuLabel = styled(({ as, ...props }) =>
  React.cloneElement(as, props)
)`
  font-size: ${({ theme }) => theme.text.size.body.body3};
  font-weight: ${({ theme }) => theme.text.weight.semibold};
  line-height: ${({ theme }) => theme.text.lineHeight.exact};
`;
