/* eslint-disable max-len */
import React, { FC } from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const InformationClipboardIcon: FC<IconProps> = (props) => {
  return (
    <BaseIcon {...props}>
      <path d="M16 12C16.6904 12 17.25 12.5596 17.25 13.25C17.25 13.9404 16.6904 14.5 16 14.5C15.3096 14.5 14.75 13.9404 14.75 13.25C14.75 12.5596 15.3096 12 16 12Z" />
      <path d="M13 17C13 16.4477 13.4477 16 14 16H16C16.5523 16 17 16.4477 17 17V22H19C19.5523 22 20 22.4477 20 23C20 23.5523 19.5523 24 19 24H13C12.4477 24 12 23.5523 12 23C12 22.4477 12.4477 22 13 22H15V18H14C13.4477 18 13 17.5523 13 17Z" />
      <path d="M13 4C11.8954 4 11 4.89543 11 6L8 6C6.89543 6 6 6.89543 6 8V26C6 27.1046 6.89543 28 8 28H24C25.1046 28 26 27.1046 26 26V8C26 6.89543 25.1046 6 24 6H21C21 4.89543 20.1046 4 19 4H13ZM19 6V8H13V6H19ZM19 10C20.1046 10 21 9.10457 21 8H24V26H8V8H11C11 9.10457 11.8954 10 13 10H19Z" />
    </BaseIcon>
  );
};
