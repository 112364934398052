import { AlertType } from "react-alert";
import styled from "styled-components";

import { Body } from "components/atoms/LegacyText";

import themeColors from "styles/config/colors";
import sizes from "styles/config/sizes";
import themeSpacing from "styles/config/spacing";

export const AlertContainer = styled.div<{ type?: AlertType }>`
  display: flex;
  gap: ${themeSpacing.xs3};
  align-items: flex-start;

  width: ${sizes.alertWidth}px;
  height: auto;
  padding: ${themeSpacing.xs3};

  color: ${themeColors.backgroundHover};
  white-space: pre-line;

  background-color: ${({ theme, type }) => {
    switch (type) {
      case "error":
        return theme.activeColorScheme.colors.backgroundDanger;
      case "success":
        return theme.activeColorScheme.colors.backgroundSuccess;
      default:
        return theme.activeColorScheme.colors.backgroundInformation;
    }
  }};
  border-radius: ${themeSpacing.radiusSmall};
`;

export const AlertIcon = styled.div`
  display: flex;
  align-items: center;
`;

export const AlertBody = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: flex-start;

  p {
    width: 100%;
    word-break: break-word;
  }
`;

export const MessageContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const MessageBody = styled(Body)`
  margin-top: ${themeSpacing.xs1};
`;

export const MessageList = styled.ul`
  list-style: outside;
`;

export const ActionButtonContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-top: ${themeSpacing.xs2};
`;
