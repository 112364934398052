import { ComponentProps, ReactNode } from "react";

import { ComponentWithChildren } from "@polyai/common/types/helpers";
import ConditionalWrapper from "@polyai/common/utils/conditionalWrapper";

import { ColorName } from "styles/config/colors";
import Tooltip from "../Tooltip";

import * as Styled from "./HelpText.styled";

export interface HelpTextProps {
  help?: ReactNode;
  portal?: boolean;
  autoUpdateOptions?: ComponentProps<typeof Tooltip>["autoUpdateOptions"];
  inline?: boolean;
  backgroundColor?: ColorName;
}

const HelpText: ComponentWithChildren<HelpTextProps> = ({
  children,
  help,
  portal = false,
  autoUpdateOptions,
  inline,
  backgroundColor,
}) => {
  return (
    <ConditionalWrapper
      condition={!!help}
      wrapper={(children) => (
        <Styled.TooltipWrapper $inline={inline}>
          <Tooltip
            autoUpdateOptions={autoUpdateOptions}
            backgroundColor={backgroundColor}
            content={help}
            placement="top-start"
            portal={portal}
          >
            <Styled.LabelWithHelp data-test-id="help-text-label" role="tooltip">
              {children}
            </Styled.LabelWithHelp>
          </Tooltip>
        </Styled.TooltipWrapper>
      )}
    >
      <span>{children}</span>
    </ConditionalWrapper>
  );
};

export default HelpText;
