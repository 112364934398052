import React from "react";

import * as Styled from "./LegacyText.styled";

export interface LegacyTextProps<T extends React.ElementType> {
  as?: T;
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

type PropTypes<T extends React.ElementType> = LegacyTextProps<T> &
  Omit<React.ComponentPropsWithoutRef<T>, keyof LegacyTextProps<T>>;

export const Body = React.forwardRef(
  <T extends React.ElementType = "p">(
    { as, ...props }: PropTypes<T>,
    ref: React.Ref<HTMLParagraphElement>
  ) => {
    const Component = as || "p";
    return <Styled.Body ref={ref} as={Component} {...props} />;
  }
);

export const BodyStrong = React.forwardRef(
  <T extends React.ElementType = "p">(
    { as, ...props }: PropTypes<T>,
    ref: React.Ref<HTMLParagraphElement>
  ) => {
    const Component = as || "p";
    return <Styled.BodyStrong ref={ref} as={Component} {...props} />;
  }
);

export const Headline = React.forwardRef(
  <T extends React.ElementType = "h1">(
    { as, ...props }: PropTypes<T>,
    ref: React.Ref<HTMLHeadingElement>
  ) => {
    const Component = as || "h1";
    return <Styled.Headline ref={ref} as={Component} {...props} />;
  }
);

export const Subtitle = React.forwardRef(
  <T extends React.ElementType = "h2">(
    { as, ...props }: PropTypes<T>,
    ref: React.Ref<HTMLHeadingElement>
  ) => {
    const Component = as || "h2";
    return <Styled.Subtitle ref={ref} as={Component} {...props} />;
  }
);

export const Title = React.forwardRef(
  <T extends React.ElementType = "h3">(
    { as, ...props }: PropTypes<T>,
    ref: React.Ref<HTMLHeadingElement>
  ) => {
    const Component = as || "h3";
    return <Styled.Title ref={ref} as={Component} {...props} />;
  }
);

export const InputText = React.forwardRef(
  <T extends React.ElementType = "p">(
    { as, ...props }: PropTypes<T>,
    ref: React.Ref<HTMLParagraphElement>
  ) => {
    const Component = as || "p";
    return <Styled.InputText ref={ref} as={Component} {...props} />;
  }
);

export const Caption = React.forwardRef(
  <T extends React.ElementType = "p">(
    { as, ...props }: PropTypes<T>,
    ref: React.Ref<HTMLParagraphElement>
  ) => {
    const Component = as || "p";
    return <Styled.Caption ref={ref} as={Component} {...props} />;
  }
);

export const CaptionStrong = React.forwardRef(
  <T extends React.ElementType = "p">(
    { as, ...props }: PropTypes<T>,
    ref: React.Ref<HTMLParagraphElement>
  ) => {
    const Component = as || "p";
    return <Styled.CaptionStrong ref={ref} as={Component} {...props} />;
  }
);

export const MenuLabel = React.forwardRef(
  <T extends React.ElementType = "p">(
    { as, ...props }: PropTypes<T>,
    ref: React.Ref<HTMLParagraphElement>
  ) => {
    const Component = as || "p";
    return <Styled.MenuLabel ref={ref} as={Component} {...props} />;
  }
);
