import { CaptionStrong } from "components/atoms/LegacyText";

import Tooltip from "../Tooltip";

import * as Styled from "./InputError.styled";

type InputErrorProps = {
  errors?: string[];
  showErrorAsTooltip?: boolean;
};

export const InputError = ({
  errors = [],
  showErrorAsTooltip,
}: InputErrorProps) => {
  if (!errors?.length) {
    return null;
  }

  if (showErrorAsTooltip) {
    return (
      <Styled.TooltipWrapper>
        <Tooltip
          content={
            <CaptionStrong data-test-id="input-error" role="alert">
              {errors.join(", ")}
            </CaptionStrong>
          }
          placement="top-start"
        >
          <Styled.TooltipIcon data-test-id="input-error-icon" customFill />
        </Tooltip>
      </Styled.TooltipWrapper>
    );
  }

  return (
    <Styled.ErrorCaption data-test-id="input-error" role="alert">
      {errors.join(", ")}
    </Styled.ErrorCaption>
  );
};

export default InputError;
