import React from "react";

import { AlertMessageType } from "@polyai/common/hooks/useBaseAlert";
import { ComponentWithChildren } from "@polyai/common/types/helpers";

import { BodyStrong } from "components/atoms/LegacyText";

import * as Styled from "./Alert.styled";

export const AlertMessage: ComponentWithChildren<AlertMessageType> = ({
  title,
  children,
  actionButton,
}) => (
  <Styled.MessageContainer>
    <BodyStrong>{title}</BodyStrong>
    {children && <Styled.MessageBody>{children}</Styled.MessageBody>}
    {actionButton && (
      <Styled.ActionButtonContainer>
        {actionButton}
      </Styled.ActionButtonContainer>
    )}
  </Styled.MessageContainer>
);

export const AlertMessageList = ({
  title,
  messages,
}: AlertMessageType & { messages: string[] }) => (
  <Styled.MessageContainer>
    <BodyStrong>{title}</BodyStrong>
    {messages?.length && (
      <Styled.MessageBody>
        <Styled.MessageList>
          {messages.map((message) => (
            <li key={message}>{message}</li>
          ))}
        </Styled.MessageList>
      </Styled.MessageBody>
    )}
  </Styled.MessageContainer>
);
