import styled from "styled-components";

import { ErrorIcon } from "components/atoms/Icons";
import { CaptionStrong } from "components/atoms/LegacyText";

import themeColors from "styles/config/colors";
import themeSpacing from "styles/config/spacing";

export const TooltipWrapper = styled.div`
  display: flex;
`;

export const TooltipIcon = styled(ErrorIcon)`
  cursor: pointer;
`;

export const ErrorCaption = styled(CaptionStrong)`
  margin-bottom: ${themeSpacing.xs2};
  color: ${themeColors.errorText01};
`;
