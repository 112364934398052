import { ComponentWithChildren } from '@polyai/common/types/helpers';
import IconButton from '@polyai/ui/components/atoms/IconButton';
import { LogoutIcon } from '@polyai/ui/components/atoms/Icons';
import { Body, Subtitle } from '@polyai/ui/components/atoms/LegacyText';

import ForbiddenImage from '@public/assets/svg/robots/403.svg';
import NotFoundImage from '@public/assets/svg/robots/404.svg';
import UnavailableImage from '@public/assets/svg/robots/503.svg';
import UnauthorizedImage from '@public/assets/svg/robots/not-registered.svg';
import { useAppSelector } from 'hooks/reduxHooks';
import { ErrorPageProps, ErrorStatus } from './types';

import * as Styled from './ErrorPage.styled';

export { ErrorStatus } from './types';

const getErrorDetails = (
  type: ErrorStatus,
  login: Function,
  logout: Function,
) => {
  switch (type) {
    case ErrorStatus.FORBIDDEN:
      return {
        image: <ForbiddenImage />,
        title: 'You don’t have access to this stuff.',
        text: 'Request access from the workspace admin.',
      };
    case ErrorStatus.NOT_FOUND:
      return {
        image: <NotFoundImage />,
        title: 'The stuff you are looking for is not here.',
        text: 'This page does not exist. Make sure to verify that the link is typed correctly.',
      };
    case ErrorStatus.UNAUTHORIZED:
      return {
        image: <UnauthorizedImage />,
        title: 'Get started with PolyAI Studio.',
        text: (
          <>
            <div>
              You&apos;re not a registered user yet. Our team can help you get
              up and running.
            </div>
            <Styled.LogoutContainer>
              <IconButton
                hoverTargetColor="borderSubtle"
                hoverTarget
                onClick={() => logout()}
              >
                <LogoutIcon />
              </IconButton>
            </Styled.LogoutContainer>
          </>
        ),
      };
    case ErrorStatus.UNAVAILABLE:
      return {
        image: <UnavailableImage />,
        title: 'PolyAI Studio is currently getting a tune-up',
        text: 'We will be back in a couple of hours. Contact your PolyAI support team for more information.',
      };
    case ErrorStatus.UNHANDLED_ERROR:
      return {
        image: <NotFoundImage />,
        title: 'There was an error accessing the Studio.',
        text: (
          <>
            Try{' '}
            <Styled.RefreshLink onClick={() => window.location.reload()}>
              refreshing the page
            </Styled.RefreshLink>
            , if the problem persists please contact your PolyAI support team.
          </>
        ),
      };
    case ErrorStatus.EMAIL_UNVERIFIED:
      return {
        image: <NotFoundImage />,
        title: 'Please verify your email address.',
        text: (
          <>
            Please click the link in the email we&apos;ve sent you and then{' '}
            <Styled.RefreshLink onClick={() => login()}>
              try logging in again
            </Styled.RefreshLink>
            , if the problem persists please contact the PolyAI support team.
          </>
        ),
      };
    default:
      return {
        image: <NotFoundImage />,
        title: 'There was an error accessing the Studio.',
        text: 'Contact your PolyAI support team if this issue persists.',
      };
  }
};

const PortalErrorPage: ComponentWithChildren<ErrorPageProps> = ({
  type,
  customMessage,
  children,
  className,
}) => {
  const login = useAppSelector((state) => state.auth.login);
  const logout = useAppSelector((state) => state.auth.logout);
  const { image, title, text } = getErrorDetails(type, login, logout);

  return (
    <Styled.Wrapper className={className}>
      {image}
      <Styled.Content>
        <Styled.Inner>
          <Styled.Logo dotsColor="iconPrimary" textColor="iconPrimary" />
          <Subtitle> {title} </Subtitle>
          <Body>{customMessage || text}</Body>
        </Styled.Inner>

        {children}
      </Styled.Content>
    </Styled.Wrapper>
  );
};

export default PortalErrorPage;
