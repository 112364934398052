import { DefaultTheme } from "styled-components";

import breakpoints from "./config/breakpoints";
import { COLOR_SCHEMES } from "./config/colors";
import sizes from "./config/sizes";
import { radius, spacing } from "./config/spacing";
import text from "./config/text";
import { THEME_NAMES } from "./config/theme.constants";
import transition from "./config/transition";

export const getAppTheme = (themeName: THEME_NAMES): DefaultTheme => ({
  themeColors: themeName,
  themeSpacing: themeName,
  activeColorScheme: COLOR_SCHEMES[themeName],
  sizes,
  spacing: { ...spacing, ...radius },
  breakpoints,
  text,
  transition,
});

export const defaultTheme = getAppTheme(THEME_NAMES.DEFAULT);
